<template>
  <div class="d-flex flex-column overflow-auto vh-100">
    <book-in-out-stock id="book-in-out-stock" :stock="selectedStock" />
    <edit-stock @hidden="selectedStock = undefined" id="edit-stock" :stock="selectedStock" />
    <sticky-header :title="$route.meta.title">
      <template #buttons>
        <b-button class="ml-2" variant="primary" :to="{name: 'stock.create'}"><fa-icon class="mr-2" icon="plus" />Create</b-button>
      </template>
    </sticky-header>
    <div class="flex-fill p-3">
      <div class="d-flex flex-column mt-5 py-3 justify-content-center" v-if="loading">
        <b-spinner class="align-self-center" variant="primary" />
        <p class="align-self-center mt-1 mb-0">Loading...</p>
      </div>
      <b-card class="text-center py-5" v-if="!loading && data.length === 0">
        <p class="h3 font-weight-bold">No stock items found.</p>
        <p class="text-muted mb-0">Before you can see any stock items here, <router-link :to="{name: 'stock.create'}">try creating one</router-link>.</p>
      </b-card>
      <b-list-group class="mb-4" v-if="!loading">
        <b-list-group-item class="d-flex" :key="item.id" v-for="item in data">
          <div class="align-self-center flex-fill">
            <p class="h5">{{ item.name }}</p>
            <p class="mb-0 text-muted">{{ item.quantity }} total items</p>
          </div>
          <div class="align-self-center d-flex flex-md-row flex-column">
            <b-button class="mb-1 mb-md-0 ml-md-1" @click.prevent="bookInOut(item.id)" variant="primary" v-b-popover.top.hover="`Book In/Out`"><fa-icon class="mr-2" icon="shipping-fast" /> Book In/Out</b-button>
            <b-button class="mb-1 mb-md-0 ml-md-1" @click.prevent="edit(item.id)" variant="primary" v-b-popover.top.hover="`Edit`"><fa-icon icon="pen" /></b-button>
            <b-button class="mb-1 mb-md-0 ml-md-1" @click.prevent="destroy(item.id)" variant="danger" v-b-popover.top.hover="`Delete`"><fa-icon icon="trash" /></b-button>
          </div>
        </b-list-group-item>
      </b-list-group>
      <b-pagination-nav :link-gen="paginationLinkGen" :number-of-pages="lastPage" use-router v-if="lastPage > 1" />
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import pickBy from 'lodash/pickBy'
import identity from 'lodash/identity'
import EditStock from './Modals/EditStock'
import BookInOutStock from './Modals/BookInOutStock'
import StickyHeader from '../components/StickyHeader'
import { mapActions, mapState } from 'vuex'

export default {
  components: { BookInOutStock, EditStock, StickyHeader },
  data() {
    return {
      selectedStock: {},
    };
  },
  async mounted() {
    if (this.$route.meta.modalId) {
      this.$bvModal.show(this.$route.meta.modalId)
    }

    await this.fetch(this.$route.query.page);
  },
  computed: {
    ...mapState('stock', ['data', 'loading', 'lastPage']),
  },
  methods: {
    ...mapActions('stock', ['fetch', 'delete']),
    moment,
    paginationLinkGen(pageNo) {
      return pageNo === 1
        ? {name: 'stock', query: pickBy({...this.$route.params, page: null}, identity)}
        : {name: 'stock', query: {...this.$route.params, page: pageNo}};
    },
    pluralise(count, term) {
      if (count === 1) return term;
      return `${term}s`;
    },
    bookInOut(id) {
       this.selectedStock = this.data.find(c => c.id === id);

      if (!(this.$route.name === 'stock.book-in-out' && this.$route.params.stockId === id)) {
        this.$router.push({ name: 'stock.book-in-out', params: { stockId: id } });
      }
    },
    edit(id) {
      this.selectedStock = this.data.find(c => c.id === id);

      if (!(this.$route.name === 'stock.update' && this.$route.params.stockId === id)) {
        this.$router.push({ name: 'stock.update', params: { stockId: id } });
      }
    },
    destroy(id) {
      return this.delete(id).then(() => this.fetch(this.$route.query.page));
    },
  },
  watch: {
    async '$route'(newValue, value) {
      if (!value.meta.modalId && newValue.meta.modalId) {
        return this.$bvModal.show(newValue.meta.modalId);
      }

      if (newValue.name === value.name && newValue.query.page !== value.query.page) {
        await this.fetch(this.$route.query.page);
      }

      return this.$bvModal.hide(value.meta.modalId);
    }
  }
}
</script>

<style>

</style>
