<template>
  <div>
    <b-modal no-close-on-backdrop :id="id" size="lg" :title="`${this.$route.params.stockId ? 'View/Edit' : 'Create'} Stock`" @hidden="closeModal">
      <b-row>
        <b-col cols="12" v-if="!loading && error">
          <b-alert variant="danger">{{ error }}</b-alert>
        </b-col>
        <b-col class="py-4 text-center" cols="12" v-if="loading">
          <b-spinner variant="primary"></b-spinner>
        </b-col>
      </b-row>
      <b-row v-if="!loading && !error">
        <b-col cols="12">
          <b-row>
            <b-col cols="12" md="8">
              <b-form-group label="Name" :invalid-feedback="getValidationMessage('name')" :state="!validationErrors.name">
                <b-form-input type="text" v-model="editedStock.name" />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="4">
              <b-form-group label="Quantity" :invalid-feedback="getValidationMessage('quantity')" :state="!validationErrors.quantity">
                <b-form-input type="number" v-model="editedStock.quantity" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group label="Description" :invalid-feedback="getValidationMessage('description')" :state="!validationErrors.description">
                <b-form-textarea v-model="editedStock.description" />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <template #modal-footer>
        <b-button @click.prevent="closeModal" variant="light">Close</b-button>
        <b-button @click.prevent="saveUser" variant="primary">
          <b-spinner class="mx-4" variant="light" small v-if="saving"/>
          <span v-if="!saving">{{ $route.params.stockId ? 'Update' : 'Create' }}</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import handleErrors from '../../mixins/handleErrors';

export default {
  mixins: [handleErrors],
  emits: ['hidden'],
  data() {
    return {
      validationErrors: {},
      genericError: undefined,
      editedStock: {
        name: '',
        description: '',
        quantity: 0,
      },
      loading: false,
      saving: false,
      error: '',
    };
  },
  props: {
    stock: Object,
    id: String,
  },
  async mounted() {
    if (this.$route.params.stockId && this.stock) {
      this.loading = true;

      try {
        const stock = await this.fetchSingle(this.$route.params.stockId);
        this.editedStock = {
            ...stock,
        };
        this.loading = false;
      } catch (err) {
        this.error = "Can not load team. Please try again later.";
        this.loading = false;
      }
    }
  },
  methods: {
    ...mapActions('stock', ['fetch', 'fetchSingle', 'updateOrCreate']),
    closeModal() {
      this.editedStock = {};
      this.validationErrors = {};
      if (this.$route.name !== this.$route.meta.parentName) {
        this.$router.push({ name: this.$route.meta.parentName });
      }
      this.$emit('hidden')
    },
    saveUser() {
      this.saving = true;
      this.updateOrCreate(this.editedStock).then(() => {
        this.saving = false;
        this.genericError = undefined;
        this.closeModal();
        return this.fetch();
      }).catch(err => {
        if (err.response && err.response.status === 422) {
          this.saving = false;
          this.handleValidationErrors(err);
        } else {
          this.saving = false;
          this.genericError = 'There was an error saving just now. Please try again later.';
        }
      });
    }
  },
  watch: {
    stock(value) {
      this.editedStock = {
        ...value,
      };
    }
  }
}
</script>

<style>
.modal-body-col {
  height: 75vh;
  overflow: auto;
}
</style>
