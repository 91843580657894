<template>
  <div>
    <b-modal no-close-on-backdrop :id="id" size="md" :title="`${this.$route.params.stockId ? 'View/Edit' : 'Create'} Stock`" @hidden="closeModal">
      <b-row>
        <b-col cols="12" v-if="!loading && error">
          <b-alert variant="danger">{{ error }}</b-alert>
        </b-col>
        <b-col class="py-4 text-center" cols="12" v-if="loading">
          <b-spinner variant="primary"></b-spinner>
        </b-col>
      </b-row>
      <b-row v-if="!loading && !error">
        <b-col cols="12">
          <b-row>
            <b-col cols="12">
              <b-form-group label="Booking In/Out?">
                <b-form-radio-group class="w-100" buttons :options="[{text: 'Book In', value: true}, {text: 'Book Out', value: false}]" button-variant="light" v-model="isClockIn" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="6">
              <b-form-group label="Change in Quantity" :invalid-feedback="validationErrors.quantity" :state="!validationErrors.quantity">
                <b-form-input type="number" min="0" v-model.number="editedStock.delta" />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group label="Quantity (After)" :invalid-feedback="(editedStock.quantity + truedelta) >= 0 ? '' : `Quantity can't be negative.`" :state="(editedStock.quantity + truedelta) >= 0">
                <b-form-input disabled type="number" :value="parseInt(editedStock.quantity) + truedelta" />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <template #modal-footer>
        <b-button @click.prevent="closeModal" variant="light">Close</b-button>
        <b-button @click.prevent="save" :disabled="editedStock.delta === 0 && (editedStock.quantity + truedelta) < 0" variant="primary">
          <b-spinner class="mx-4" variant="light" small v-if="saving"/>
          <span v-if="!saving">Book In/Out</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data() {
    return {
      validationErrors: {},
      genericError: undefined,
      isClockIn: true,
      editedStock: {
        delta: 0,
        quantity: 0,
      },
      loading: false,
      saving: false,
      error: '',
    };
  },
  props: {
    stock: Object,
    id: String,
  },
  async mounted() {
    if (this.$route.params.stockId && this.stock) {
      this.loading = true;

      try {
        const stock = await this.fetchSingle(this.$route.params.stockId);
        this.editedStock = {
            delta: 0,
            quantity: stock.quantity,
        };
        this.loading = false;
      } catch (err) {
        this.error = "Can not load team. Please try again later.";
        this.loading = false;
      }
    }
  },
  computed: {
    truedelta() {
      return this.isClockIn ? parseInt(this.editedStock.delta) : parseInt("-" + this.editedStock.delta);
    }
  },
  methods: {
    ...mapActions('stock', ['fetch', 'fetchSingle', 'bookInOut']),
    closeModal() {
      this.validationErrors = {};
      if (this.$route.name !== this.$route.meta.parentName) {
        this.$router.push({ name: this.$route.meta.parentName });
      }
    },
    save() {
      this.saving = true;
      this.bookInOut({
        id: this.$route.params.stockId,
        delta: this.truedelta,
      }).then(() => {
        this.saving = false;
        this.genericError = undefined;
        this.closeModal();
        return this.fetch();
      }).catch(err => {
        if (err.response && err.response.status === 422) {
          this.saving = false;
          err.response.json().then(json => {
            this.validationErrors = json.errors;
          });
        } else {
          this.saving = false;
          this.genericError = 'There was an error saving just now. Please try again later.';
        }
      });
    }
  },
  watch: {
    stock(value) {
      this.editedStock = {
        delta: 0,
        quantity: value.quantity,
      };
    }
  }
}
</script>

<style>
.modal-body-col {
  height: 75vh;
  overflow: auto;
}
</style>
